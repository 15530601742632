<template>
  <label v-bind:for="component_id" v-if="component_meta.component.properties.show_label == 'Y'">
    {{ component_meta.label }}：
  </label>
  <span class="tip" v-if="component_meta.component.properties.show_label == 'Y'">{{ component_meta.tip }}</span>

  <label v-bind:for="'new-voice-' + component_id" v-show="can_upload" class="btn btn-default bg-green float-right">
    <i class="fas fa-plus"></i>
    <input type="file" v-bind:id="'new-voice-' + component_id" multiple="multiple" style="display:none"
      @change="upload_file" accept="audio/*">
  </label>
  <table class="table table-striped" v-bind:id="component_id">
    <thead v-show="voices.length > 1">
      <tr>
        <th></th>
        <th>音檔</th>
        <th class="operate">動作</th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(voice,index) in voices" v-bind:key="Math.random() + '-' + index">
        <tr v-bind:id="voice.src">
          <td class="sort-drop" v-show="voices.length > 1">
            <i class="fas fa-grip-vertical"></i>
          </td>

          <td style="width:90%;">
            <audio controls>
              <source v-bind:src="voice.src" type="audio/mpeg">
              Your browser does not support the audio element.
            </audio>
          </td>

          <td>
            <button type="button" @click="delete_voice(voice.path)" class="btn btn-default btn-sm bg-red"><i
                class="fas fa-trash-alt"></i></button>
          </td>
        </tr>
      </template>

    </tbody>
  </table>

  <div class="invalid-mssage">{{ component_message }}</div>

  <div v-show="component_to_label">
    {{ component_value }}
  </div>
</template>

<script>
import axios from 'axios';
export default {
  props: ['field', 'value', 'message'],
  emits: ['input'],
  data() {
    let localMeta = this.field;
    if (localMeta.component.properties.show_label == undefined) {
      localMeta.component.properties.show_label = '';
    }


    let localValue = this.value;

    if (localValue == null || localValue == undefined || localValue == '') {
      localValue = [];
    }

    // 如果 localValue 是字串則轉成陣列
    else if (typeof localValue === 'string' || localValue instanceof String) {
      localValue = [localValue];
    }



    // 將帶入的資料轉換成圖片物件
    let voices = [];
    for (let i = 0; i < localValue.length; i++) {
      voices.push({
        'id': Math.random().toString().substring(2),
        'src': localValue[i],
        'progress': 0,
        'path': localValue[i]
      });
    }

    if (localMeta.component.properties.base_url != undefined && localMeta.component.properties.base_url != '') {
      for (let i = 0; i < voices.length; i++) {
        voices[i].src = localMeta.component.properties.base_url + voices[i].src;
      }

    }

    // 如果沒有設定上傳數量，則預設為無限
    if (localMeta.component.properties.count == undefined || localMeta.component.properties.count == 0) {
      localMeta.component.properties.count = 10000000000;
    }

    // 如果沒有設定上傳容量，則預設為無限
    if (localMeta.component.properties.max_size == undefined && localMeta.component.properties.max_size == '') {
      localMeta.component.properties.max_size = 0;
    }

    // 上傳網址
    let api_url = '';
    if (localMeta.component.properties.upload_api != undefined) {
      // 組合 API 網址
      api_url = this.$microlink.base_api_url + localMeta.component.properties.upload_api;
    }

    let can_upload = (localValue.length < localMeta.component.properties.count);

    return {
      // 元件識別碼
      component_id: 'voice-' + Math.random().toString().substring(2),

      // 欄位資訊
      component_meta: localMeta,

      // 元件值
      component_value: localValue,

      // 元件訊息
      component_message: this.message,

      // 元件是否被標籤化
      component_to_label: (localMeta.component.properties.to_label == 'Y') ? true : false,

      // 圖片元件格式
      voices: voices,

      // 元件是否可以上傳
      can_upload: can_upload,

      // 上傳網址
      upload_url: api_url,

      // 上傳最大容量限制
      upload_max_size: localMeta.component.properties.max_size
    }
  },
  watch: {
    message: function (new_value, _) {
      _;
      this.component_message = new_value;
    }
  },
  methods: {
    upload_file: function ($event) {

      if ($event.target.files.length == 0) {
        return;
      }

      let $this = this;

      


      for (let i = 0; i < $event.target.files.length; i++) {
        let tempFile = $event.target.files[i]

        if (this.upload_max_size > 0) {
          // 將容量轉換成 KB
          let fileSize = Math.round(tempFile.size / 1024 * 100) / 100;

          // 檢查檔案大小
          if (fileSize > this.upload_max_size) {
            // 文件大小超限了
            this.$microlink.alert('檔案大小超過限制', '檔案名稱：' + tempFile.name, 'warning');
            continue;
          }
        }

        let file = new FormData()
        file.append('file', tempFile)

        let voice_id = Math.random().toString().substring(2);
        let voice = { 'src': URL.createObjectURL(tempFile), 'progress': 0, 'id': voice_id, 'path': '' };
        this.voices.push(voice);

        $event.target.value = '';

        axios({
          method: 'post',
          url: this.upload_url,
          data: file,
          withCredentials: true,
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: function (progressEvent) {
            const complete = parseInt((progressEvent.loaded / progressEvent.total * 100 | 0));

            // 快速根據 voice_id 找到對應的圖片物件
            let voice = $this.voices.find(voice => voice.id == voice_id);
            voice.progress = complete;

            // 上傳進度
            console.log('上傳進度', voice.progress);
          }
        }).then(res => {

          let response = res.data;
          if (response.status == 'success') {
            // 將上傳成功的圖片網址加入到 voices 陣列中
            let voice = $this.voices.find(voice => voice.id == voice_id);
            voice.src = response.data.url;
            voice.progress = 100;
            voice.path = response.data.path;
            $this.input_event();

            // 如果上傳成功的圖片數量已經達到上限，則不允許再上傳
            if ($this.component_value.length >= $this.component_meta.component.properties.count) {
              $this.can_upload = false;
            }

            // 將元件值傳出去
            // $this.$emit('input', $this.component_value);
          } else {
            // 上傳失敗
            $this.$microlink.alert('上傳失敗', response.message, 'error');
          }


        }).catch(err => {
          // 捕獲異常並處理
          console.log(err)
        });
      }
    },
    delete_voice: function (voice_path) {
      
      // 詢問是否確定刪除
      this.$microlink.confirm('確定要刪除嗎？', '刪除後無法復原', (result) => {
        if (result.isConfirmed) {
          // 將圖片路徑轉換成 base64
          let voice_path_64 = this.$microlink.base64_encode(voice_path);

          // 取得刪除的 API 網址
          let api_url = this.$microlink.base_api_url + this.component_meta.component.properties.delete_api;
          this.$microlink.api_post(api_url, { 'fd': voice_path_64 }, true, (response) => {

            if (response.status == 'success') {
              // 刪除成功
              this.$microlink.alert('刪除成功', response.message, 'success');

              // 將圖片從 voices 陣列中移除
              let voice = this.voices.find(voice => voice.path == voice_path);
              this.voices.splice(this.voices.indexOf(voice), 1);

              // 將元件值傳出去
              this.input_event();

              // 如果上傳成功的圖片數量已經達到上限，則不允許再上傳
              if (this.component_value.length < this.component_meta.component.properties.count) {
                this.can_upload = true;
              }
            } else {
              // 刪除失敗
              this.$microlink.alert('刪除失敗', response.message, 'error');
            }
          });
        }
      });
    },
    input_event: function () {

      let $event = {};
      if (this.voices.length == 0) {
        this.component_value = '';
        $event = { 'target': { 'value': '' } };
        $event.target.value = this.component_value;
      }
      else if (this.voices.length == 1) {
        if (this.voices[0].path == undefined || this.voices[0].path == '') {
          return;
        }
        this.component_value = this.voices[0].path;

        $event = { 'target': { 'value': '' } };
        $event.target.value = this.component_value;
      }
      else {
        this.component_value = [];
        for (let i = 0; i < this.voices.length; i++) {
          if (this.voices[i].path == undefined || this.voices[i].path == '') {
            continue;
          }
          this.component_value.push(this.voices[i].path);
        }
        $event = { 'temp': [] };
        $event.temp = this.component_value;
      }

      this.$emit('input', $event)
    }
  }
}
</script>

<style scoped>
span.tip {
  color: green;
}

div.invalid-mssage {
  color: red;
  font-size: 12px;
  margin-top: 3px;
}

td i.fa-grip-vertical {
  cursor: pointer;
}

td {
  vertical-align: middle !important;
}

td audio {
  width: 100%;
}
</style>